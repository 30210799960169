import "./styles.scss";
import NewButton from "../../NewButton";
import React, {useEffect, useState} from "react";
import {useWindowWidth} from "../../../services/hooks";

const SpringSignupBanner = () => {
  const width = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(width <= 768)
  }, [width])

  return (
    <div className="spring-sale-signup-banner grid-section">
      <div className="content-container">
        <div className="percent-off-section">
          <h3 className="spring-promo-text">spring is in sight</h3>
          <h2 className="percent-off">40% OFF</h2>
        </div>
        <div className="description-section">
          <span className="sale-description">Shop our selection of contact lenses from popular brands and save on your first order.</span>
          <span className="limited-time-text">{isMobile
            ? "limited time only\nnow through april 1"
            : "limited time only — now through april 1"
          }</span>
          <NewButton
            className="shop-button"
            label="Shop Now"
            color="black"
            to="#order-section"
          />
        </div>
      </div>
    </div>
  )
}

export default SpringSignupBanner;
